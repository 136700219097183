<template>
  <div class="min-vh-100 d-flex align-items-center">
    <div class="account-pages w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div>
              <div class="card">
                <div class="card-body p-4">
                  <div class="text-center mt-2 mb-4">
                    <h5>{{ $t("resetPassword.resetPassword") }}</h5>
                  </div>
                  <!-- SEND USER NAME -->
                  <b-form v-if="!isThereAnUser" @submit.prevent="userExist">
                    <b-form-group id="input-group-1" class="mb-3" :label="$t('login.userName')" label-for="input-1">
                      <b-form-input id="input-1" v-model="userName" type="text" :placeholder="$t('login.userName')"
                        :class="{
                          'is-invalid': submitted && $v.userName.$error,
                        }"></b-form-input>
                      <div v-if="submitted && $v.userName.$error" class="invalid-feedback">
                        <span v-if="!$v.userName.required">Nombre de usuario es requerido.</span>
                      </div>
                    </b-form-group>
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="w-sm">{{ $t("login.login") }}</b-button>
                    </div>
                  </b-form>
                  <!-- GET QUESTION METHOD -->
                  <div class="questionMethod" v-if="isSelected && !selected && isThereAnUser">
                    <div class="p-2 mt-4">
                      <div class="alert alert-success text-center mb-4" role="alert">
                        {{ $t("resetPassword.selectMode") }}
                      </div>
                      <form @submit.prevent="tryToReset">
                        <div class="mb-3">
                          <b-form-group>
                            <div class="d-flex align-items-start">
                              <b-form-radio class="mb-3" v-model="selected" name="forRecoverPassword"
                                id="securityQuestions" value="securityQuestions">
                              </b-form-radio>
                              <label for="securityQuestions" class="ms-3 mb-0">{{ $t("resetPassword.securityQuestions")
                              }}</label>
                            </div>
                            <div class="d-flex align-items-start">
                              <b-form-radio v-model="selected" name="forRecoverPassword" value="smsToPhone"
                                id="smsToPhone"></b-form-radio>
                              <label for="smsToPhone" class="ms-3 mb-0">{{ $t("resetPassword.receiveMessageOnPhone")
                              }}</label>
                            </div>
                          </b-form-group>
                        </div>
                        <div class="mt-4 text-center">
                          <p class="mb-0">
                            {{ $t("resetPassword.didYouRemember") }}
                            <router-link to="/login" class="fw-medium text-primary">{{ $t('login.login') }}</router-link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!-- SECURITY QUESTIONS -->
                  <b-form v-if="selected === 'securityQuestions'">
                    <security-question ref="firstQuestion" :questionId="questions[0]._id"
                      :question="questions[0].question" :title="$t('security.firstQuestion')" />
                    <b-form-input type="text" :placeholder="$t('faq.answer')" required v-model="firstAnswer" :class="{
                      'is-invalid': $v.firstAnswer.$error,
                      'mb-4': !$v.firstAnswer.$error,
                    }"></b-form-input>
                    <div v-if="$v.firstAnswer.$error" class="invalid-feedback mb-2">
                      <span v-if="!$v.firstAnswer.required">{{ $t('security.firstQuestion') }} es requerido.</span>
                    </div>
                    <security-question ref="secondQuestion" :questionId="questions[1]._id"
                      :question="questions[1].question" :title="$t('security.secondQuestion')" />
                    <b-form-input type="text" :placeholder="$t('faq.answer')" required v-model="secondAnswer" :class="{
                      'is-invalid': $v.secondAnswer.$error,
                      'mb-4': !$v.secondAnswer.$error,
                    }"></b-form-input>
                    <div v-if="$v.secondAnswer.$error" class="invalid-feedback mb-2">
                      <span v-if="!$v.secondAnswer.required">{{ $t('security.secondQuestion') }} es requerido.</span>
                    </div>
                    <b-button type="submit" @click="handleSubmit" variant="primary">{{ $t('helpers.send') }}</b-button>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import appConfig from "@/app.config";

import { required } from "vuelidate/lib/validators";
import securityQuestion from "../../../components/securityQuestion.vue";
import Swal from "sweetalert2";
import { getUserinfo } from "../../../helpers/userActions/userActions";

//TODO: IMPORTAR PREGUNTAR DE SEGURIDAD Y MAQUETAR EL APARTADO PARA QUE EL CLIENTE ELIGA LAS PREGUNTAS Y RESPONDA

export default {
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    securityQuestion,
  },
  data() {
    return {
      submitted: false,
      error: null,
      title: "Recover Password",
      selected: "",
      isSelected: true,
      securityQuestionView: false,
      form: {
        firstQuestion: {
          question: null,
          answer: null,
        },
        secondQuestion: {
          question: null,
          answer: null,
        },
      },
      firstAnswer: null,
      secondAnswer: null,
      questions: null,
      isThereAnUser: false,
      userName: null,
    };
  },
  validations: {
    firstAnswer: {
      required,
    },
    secondAnswer: {
      required,
    },
    userName: {
      required,
    },
  },
  methods: {
    // Try to register the user in with the email, fullname
    // and password they provided.
    async handleSubmit(e) {
      e.preventDefault();
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const { id } = getUserinfo();
        const options = [
          {
            questionId: this.questions[0]._id,
            answer: this.firstAnswer,
          },
          {
            questionId: this.questions[1]._id,
            answer: this.secondAnswer,
          },
        ];

        try {
          const { data } = await this.$http.post(
            `questions/compare/${id}`,
            options
          );

          if (data.true) {
            return Swal.fire({
              title: "Respuestas enviadas con exito",
              text: "En un momento será redirigido",
              icon: "success",
              confirmButtonText: "Ok",
              timer: 1500,
            }).then(() => {
              setTimeout(() => {
                this.$router.push({ path: "/reset-password" });
              }, 1500);
            });
          }

          return Swal.fire({
            title: data.msg,
            text: "Compruebe por favor",
            icon: "info",
            confirmButtonText: "Ok",
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    userExist() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        if (!this.userName) return false;

        this.$http
          .get(`auth/${this.userName}`)
          .then(({ data }) => {
            if (!data.ok) {
              return Swal.fire({
                title: data.msg,
                showConfirmButton: true,
                icon: "info",
              });
            }

            return this.getUserQuestions(data._id);
          })
          .catch((err) => console.log(err));
      }
    },
    async getUserQuestions(id) {
      try {
        const { data } = await this.$http.get(`questions/${id}`);

        const questions = data.data;
        const questionsToShow = questions.map((element) => {
          const { question, _id } = element.question;
          return {
            question,
            _id,
          };
        });

        if (!questionsToShow.length) {
          return Swal.fire({
            title: "Este usuario no tiene preguntas de seguridad",
            showConfirmButton: true,
            icon: "info",
          });
        }

        this.questions = questionsToShow;
        this.isThereAnUser = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" module></style>
