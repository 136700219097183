<template>
  <div :data-questionId="questionId">
    <h5>{{ title }}</h5>
    <p>{{ question }}</p>
  </div>
</template>

<script>
export default {
  props: ["title", "questionId", "question"],
};
</script>
